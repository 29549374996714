
import * as Yup from "yup";
import Modal from '@mui/material/Modal';
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState, useEffect, useCallback } from "react";
import Iconify from 'src/components/iconify';
import Alert from "@mui/material/Alert";
import { useSnackbar } from 'src/components/snackbar';

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Stack from "@mui/material/Stack";
import { UploadAvatar, Upload, UploadBox } from '../../components/upload';
import "./bulk_upload.css";

import { paths } from 'src/routes/paths';

import FormProvider, {
  RHFUploadBox
} from 'src/components/hook-form';
import { HOST_API } from "src/config-global";
import { useParams } from "react-router";
const style = {
  display: 'flex',
  justifyContent: 'center',
  margin: 'auto',
  maxWidth: '520px',
  borderRadius: "8px"

};
export default function BulkUploadInfluencerModal(props) {
  const [files, setFiles] = useState([]);
  const [fileUpload, setfileUpload] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [errorMsg, setErrorMsg] = useState("");
  const [uplodedFileName, setuplodedFileName] = useState("");
  const [error, setError] = useState(false);
  const [errorMsgbulkUpload, seterrorMsgbulkUpload] = useState([])


  const handleDrop = useCallback(
    (acceptedFiles) => {
      const filenmeArray = acceptedFiles[0].name.split('.');
      const fileNameExtension = acceptedFiles[0].name.split('.').length - 1;
      const finalFileExtension = filenmeArray[fileNameExtension].toLowerCase();
      if (finalFileExtension === 'xls' || finalFileExtension === 'xlsx') {
        setErrorMsg("")
        setuplodedFileName(filenmeArray[0])
        const newFiles = acceptedFiles[0];
        setFiles(newFiles);
        enqueueSnackbar('File ready to upload!');
        setfileUpload(old => !old);

      } else {
        enqueueSnackbar("Upload xlsx file only!", { variant: 'error' });
      }

    },
    [files]
  );
  const ProfileSchema = Yup.object().shape({
  });
  const methods = useForm({
    resolver: yupResolver(ProfileSchema),
  });
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const { id: listId } = useParams();

  const addInfluencer = () => {
    props.onClose()
  }
  const renderErrorMessage = (
    <Box className="errorMessageForBulkUpload">
      {errorMsgbulkUpload?.map(object => <Alert severity="error">{object}</Alert>)}
    </Box>

  )
  const onSubmit = handleSubmit(async (data) => {
    if (fileUpload) {
      const formData = new FormData();
      formData.append('file', files);
      const Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
      const header = {
        headers: {
          Authorization,
        },
        body: formData,
      };
      const apiUrl = `${HOST_API}/api/influencer/bulk_upload_influencer/`;
      const apiUrlAddToList = `${HOST_API}/api/lists/${props.listid || listId}/influencers/`;
      fetch(apiUrl, {
        method: "POST",
        headers: header.headers,
        body: header.body,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.success) {
            enqueueSnackbar("Creator created successfully!")
            addInfluencer();
            setfileUpload(old => !old);
            const influencers = response.influencers;
            props.setCreateInfluencerUpload(true);
            if (props.bulkToList) {
              const headerForList = {
                headers: {
                  Authorization,
                  "Content-Type": "application/json",
                },
              };
              fetch(apiUrlAddToList, {
                method: "POST",
                headers: headerForList.headers,
                body: JSON.stringify({ influencer_ids: influencers })
              })
                .then((response) => response.json())
                .then((response) => {
                  props.setShowAddToListModal(false)
                  window.location.replace(`${window.location.protocol}//${window.location.host}/dashboard/list/${props.listid || listId}`);
                })
            }
          }
          else {
            enqueueSnackbar(response.error, { variant: 'error' });
            setfileUpload(old => !old);
          }
        })
        .catch((error) => {
          console.error("API Error:", error);
          enqueueSnackbar(error, { variant: 'error' });
          setfileUpload(old => !old);
        });
    }
  });
  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5, textAlign: "center" }}>
      <div className="bulk-title">Upload a Spreadsheet</div>
    </Stack>
  );
  const renderForm = (
    <Stack spacing={2.5}>
      {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
      <div className="bulk_parent_box">

        <UploadBox
          onDrop={handleDrop}
          placeholder={
            <Stack spacing={0.5} alignItems="center" sx={{ color: 'text.disabled' }}>
              <Iconify icon="eva:cloud-upload-fill" width={40} />
              <Typography variant="caption" sx={{
                mt: 3,
                mx: 'auto',
                display: 'block',
                textAlign: 'center',
                color: 'text.disabled',
              }}>  Allowed *.XLSX,</Typography>

            </Stack>
          }
          sx={{
            mb: 3,
            py: 2.5,
            width: 'auto',
            height: 'auto',
            borderRadius: 1.5,
          }}
        />
        <Typography variant="h4">{uplodedFileName}</Typography>

      </div>

      <div className="button-conteiner">
        <LoadingButton
          style={{ backgroundColor: '#458DE1', color: 'white', maxWidth: '150px', marginRight: '20px' }}
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          disabled={!fileUpload}
        >
          Upload
        </LoadingButton>

        <LoadingButton
          style={{ backgroundColor: '#ebeaea', maxWidth: '150px' }}
          fullWidth
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          onClick={() => addInfluencer()}

        >
          Cancel
        </LoadingButton>
      </div>
      {errorMsgbulkUpload && renderErrorMessage}

    </Stack>

  );
  return (
    <Box sx={style}>
      <FormProvider methods={methods} onSubmit={onSubmit} >
        {renderHead}
        {renderForm}
      </FormProvider>

    </Box>
  )
}

